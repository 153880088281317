import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { supabase } from '../supabaseClient';
import './ProjectSelection.css';
import { PlotIcon, EllipseIcon, LightModeIcon, OptionIcon, EllipseSpacer, SingleEllipse, CardHomeIcon, CardBookmarkIcon } from './SVGLibrary';
import { v4 as uuidv4 } from 'uuid'; // Install uuid library if not already installed
import { generateThumbnail } from '../services/thumbnailService';
import { formatDistanceToNow } from 'date-fns';
import Helper from './Helper';
import slugify from 'slugify'; // Import slugify at the top

const AI_API_URL = process.env.REACT_APP_AI_API_URL || 'https://pagerift-writer.ue.r.appspot.com/api';

const ProjectSelection = ({ user }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    fetchProjects();
  }, [user]); // Add user as a dependency

  const fetchProjects = async () => {
    setLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from('projects')
        .select('id, user_id, title, slug, description, content, image, updated_at') // Include slug
        .eq('user_id', user.id);

      if (error) throw error;

      console.log('Fetched projects:', data);
      setProjects(data);
      setLoading(false);

      // Generate thumbnails for projects without images
      data.forEach(project => {
        if (!project.image) {
          generateThumbnailForProject(project.id);
        }
      });
    } catch (error) {
      console.error('Error fetching projects:', error);
      setError('Failed to load projects. Please try again.');
      setLoading(false);
    }
  };

  const generateThumbnailForProject = async (projectId) => {
    try {
      const thumbnailUrl = await generateThumbnail(projectId);
      if (thumbnailUrl) {
        // Update the project in the local state
        setProjects(prevProjects => 
          prevProjects.map(project => 
            project.id === projectId ? { ...project, image: thumbnailUrl } : project
          )
        );
      }
    } catch (error) {
      console.error(`Error generating thumbnail for project ${projectId}:`, error);
    }
  };

  const createNewProject = async () => {
    try {
      const projectId = uuidv4(); // Generate a new UUID
      const defaultTitle = 'New Project';
      const slugBase = slugify(defaultTitle, { lower: true });

      // Use a longer portion of the UUID
      const uniqueIdPart = projectId.replace(/-/g, '').substring(0, 12);
      let slug = `${slugBase}-${uniqueIdPart}`;

      // Ensure slug uniqueness
      let slugExists = true;
      let attempt = 1;
      while (slugExists) {
        const { data: existingProject } = await supabase
          .from('projects')
          .select('id')
          .eq('slug', slug)
          .single();

        if (existingProject) {
          // Append a number to the slug if it already exists
          slug = `${slugBase}-${uniqueIdPart}-${attempt}`;
          attempt += 1;
        } else {
          slugExists = false;
        }
      }

      const newProject = {
        id: projectId,
        user_id: user.id,
        title: defaultTitle,
        slug: slug,
        description: 'A new writing project',
        content: { type: "doc", content: [{ type: "paragraph" }] },
      };

      const { data, error } = await supabase
        .from('projects')
        .insert([newProject])
        .single();

      if (error) throw error;

      console.log('Created new project:', data);

      if (data) {
        setProjects(prevProjects => [...prevProjects, data]);
        generateThumbnailForProject(data.id);
        navigate(`/projects/${data.slug}`);
      } else {
        console.error('New project data is null');
      }
    } catch (error) {
      console.error('Error creating new project:', error);
    }
  };

  const handleProjectClick = (projectSlug) => {
    navigate(`/projects/${projectSlug}`);
  };

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/'); // Redirect to home or login page after sign out
    } catch (error) {
      console.error('Error signing out:', error.message);
    }
  };

  if (loading) {
    return <div>Loading projects...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="project-selection">
      <div className="project-selection-sidebar">
        <header className="project-selection-header">
          <div className="profile-icon"></div>
          
        </header>
        <main className="project-selection-main">
          <div className="project-options">
            <button className="project-recents project-button">
              <span>Recents</span>
            </button>
            <button className="project-bookmarks project-button">
                <span>Bookmarks</span>
            </button>
            <button className="project-discover project-button">
                <span>Discover</span>
            </button>
          </div>

          <div className="project-library">
            <h2 className="library-title">Library</h2>
            <div className="category-list">
                <button className="category-item">
                    <SingleEllipse />
                    <span>Category name</span>
                </button>
                <button className="category-item">
                    <SingleEllipse />
                    <span>Category name</span>
                </button>

            </div>
          </div>
         
        </main>
        <footer className="project-selection-footer">
            <button className="footer-button">
                <PlotIcon />
                <span>Trash</span>
            </button>
         
        </footer>
      </div>
      <div className="project-selection-content">
        <header className="project-content-header">
          <button className="sign-out-button" onClick={handleSignOut}>Sign Out</button>
            <button className="new-project" onClick={createNewProject}>
                <span>New project</span>
            </button>
            <button className="header-button light-mode">
              <LightModeIcon />
            </button>
            <button className="header-button">
              <OptionIcon />
            </button>
        </header>
        <div className="project-card-wrapper">
          <Helper />
            {projects.map((project) => (
              project && (
                <div key={project.id} className="project-card" onClick={() => handleProjectClick(project.slug)}>
                  
                    <div className='project-card-image'>
                      {project.image ? (
                        <img src={project.image} alt={project.title || 'Project thumbnail'} />
                      ) : (
                        <div className="thumbnail-placeholder">Generating...</div>
                      )}
                    
                    
                  </div>
                  <div className='project-card-info'>
                    <div className="project-card-title">
                      <span>{project.title}</span>
                    </div>
                    <div className="card-footer">
                      <div className="card-edited">
                      <span className="last-updated">{formatRelativeTime(project.updated_at)}</span>
                      </div>
                      <div className="card-options">
                        <button className="card-option card-option-home">
                          <CardHomeIcon />
                        </button>
                        <button className="card-option card-option-bookmark">
                          <CardBookmarkIcon />
                        </button>
                      </div>
                    
                    </div> 
                  </div>
                  
                </div>
              )
            ))}
        </div>
        
      </div>
    </div>
  );
};

export default ProjectSelection;

// Add this function outside of your component
function formatRelativeTime(dateString) {
  const date = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInWeeks = Math.floor(diffInDays / 7);
  const diffInMonths = Math.floor(diffInDays / 30);
  const diffInYears = Math.floor(diffInDays / 365);

  if (diffInSeconds < 60) return 'just now';
  if (diffInMinutes < 60) return `${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'} ago`;
  if (diffInHours < 24) return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`;
  if (diffInDays < 7) return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
  if (diffInWeeks < 4) return `${diffInWeeks} ${diffInWeeks === 1 ? 'week' : 'weeks'} ago`;
  if (diffInMonths < 12) return `${diffInMonths} ${diffInMonths === 1 ? 'month' : 'months'} ago`;
  return `${diffInYears} ${diffInYears === 1 ? 'year' : 'years'} ago`;
}