import React from 'react';
import './EditorContent.css';
import { HelperSuggestion} from './SVGLibrary.js';

const Helper = () => {
  return (
    <div className="helper-container">
        <header className="helper-header">
            <span className="helper-icon"><HelperSuggestion /></span>
            <span className="helper-title">A general suggestion</span>
        </header>
        <main className="helper-main">
            <div className="helper-main-header">
             <span>How about this:</span>
            </div>
            <div className="helper-content">
                <div className="helper-suggestion">
                <span>Talia stood motionless, recognizing Kael's voice instantly—her mentor and the Shadowseekers' most formidable operative.</span> 
                </div>
                <div className="helper-feedback">
                 <span>The revision strengthens visuals, emphasizes familiarity, tightens description, and enhances dynamism while preserving core elements.</span> 
                </div>
                <button className="helper-button">
                    <span>Accept change</span>
                </button>
            </div>
        </main>
    </div>
  );
};

export default Helper;
