const AI_API_URL = process.env.REACT_APP_AI_API_URL || 'https://pagerift-writer.ue.r.appspot.com/api';

export const generateThumbnail = async (projectId) => {
  try {
    const response = await fetch(`${AI_API_URL}/generate_thumbnail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ project_id: projectId }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data.thumbnail_url;
  } catch (error) {
    console.error('Error generating thumbnail:', error);
    throw error;
  }
};