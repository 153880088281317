import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'; // Import Navigate
import MainEditor from './components/MainEditor';
import AuthComponent from './components/AuthComponent';
import ProjectSelection from './components/ProjectSelection';
import './global.css';
import { supabase } from './supabaseClient';
import { useTheme } from './ThemeContext'; // Import useTheme
import PrivateRoute from './components/PrivateRoute';

function App() {
	const { currentTheme } = useTheme(); // Now safe to use useTheme
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		checkUser();

		const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
			if (event === 'SIGNED_OUT') {
				setUser(null);
			} else if (event === 'SIGNED_IN') {
				setUser(session.user);
			}
		});

		return () => {
			if (subscription) {
				subscription.unsubscribe();
			}
		};
	}, []);

	const checkUser = async () => {
		const { data: { user } } = await supabase.auth.getUser();
		setUser(user);
		setLoading(false);
	};

	if (loading) {
		return (
			<div className="flex items-center justify-center h-screen text-xl font-semibold">
				Loading...
			</div>
		);
	}

	return (
		<div className={currentTheme ? `${currentTheme}-theme` : 'light-theme'}>
			{!user ? (
				<AuthComponent onLogin={setUser} />
			) : (
				<Routes>
					<Route
						path="/projects"
						element={
							<PrivateRoute user={user}>
								<ProjectSelection user={user} />
							</PrivateRoute>
						}
					/>
					<Route
						path="/projects/:slug"
						element={
							<PrivateRoute user={user}>
								<MainEditor user={user} />
							</PrivateRoute>
						}
					/>
					{/* Redirect to /projects as default */}
					<Route path="*" element={<Navigate to="/projects" replace />} />
				</Routes>
			)}
		</div>
	);
}

export default App;