import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import LeftSidebar from './LeftSidebar';
import RightSidebar from './RightSidebar';
import TiptapEditor from './EditorContent';
import './MainEditor.css';
import { supabase } from '../supabaseClient';
import CharacterCount from './CharacterCount';
import HorizontalHeader from './HorizontalHeader';
import slugify from 'slugify';

const MainEditor = ({ user }) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(null);
  const [title, setTitle] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [projectId, setProjectId] = useState(null);
  const [originalText, setOriginalText] = useState('');
  const [rewriteOptions, setRewriteOptions] = useState([]);
  const [operationType, setOperationType] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const [worldId, setWorldId] = useState(null);
  const editorRef = useRef(null);
  const [isLeftSidebarExtended, setIsLeftSidebarExtended] = useState(false);

  // Ref flag to prevent immediate re-loading of project data after navigation
  const justNavigated = useRef(false);

  useEffect(() => {
    if (!justNavigated.current) {
      loadProjectData(slug);
    } else {
      // Reset the flag after preventing the initial load
      justNavigated.current = false;
    }
  }, [slug]);

  const loadProjectData = async (currentSlug) => {
    setIsLoading(true);

    const { data, error } = await supabase
      .from('projects')
      .select('*')
      .eq('slug', currentSlug)
      .single();

    if (error || !data) {
      console.error('Error loading project data:', error);
      alert('Project not found or you do not have access to it.');
      navigate('/projects');
      return;
    }

    if (data.user_id !== user.id) {
      alert('You do not have permission to access this project.');
      navigate('/projects');
      return;
    }

    setEditorState(data.content || { type: 'doc', content: [{ type: 'paragraph' }] });
    setTitle(data.title || '');
    setProjectId(data.id);
    setWorldId(data.world_id);
    setIsLoading(false);
  };

  const saveProjectData = async (newContent, newTitle) => {
    setIsSaving(true);

    const uniqueIdPart = projectId.replace(/-/g, '').substring(0, 12);
    const slugBase = slugify(newTitle || 'Untitled', { lower: true });
    let newSlug = `${slugBase}-${uniqueIdPart}`;

    // Check if the title has changed
    if (newTitle !== title) {
      let slugExists = true;
      let attempt = 1;
      while (slugExists) {
        const { data: existingProject } = await supabase
          .from('projects')
          .select('id')
          .eq('slug', newSlug)
          .single();

        if (existingProject && existingProject.id !== projectId) {
          // Slug already exists, append an attempt number
          newSlug = `${slugBase}-${uniqueIdPart}-${attempt}`;
          attempt += 1;
        } else {
          slugExists = false;
        }
      }
    } else {
      newSlug = slug;
    }

    // Update the project in the database
    const { data, error } = await supabase
      .from('projects')
      .update({
        content: newContent,
        title: newTitle,
        slug: newSlug,
        updated_at: new Date(),
      })
      .eq('id', projectId)
      .select()
      .single();

    if (error) {
      console.error('Error saving project data:', error);
    } else {
      console.log('Project saved successfully');

      // Update the state with the new title
      setTitle(newTitle);

      // Update the URL if the slug has changed
      if (newSlug !== slug) {
        justNavigated.current = true; // Set the flag before navigating
        navigate(`/projects/${newSlug}`, { replace: true });
      }
    }
    setIsSaving(false);
  };

  const debouncedSave = useCallback(
    debounce((newContent) => {
      saveProjectData(newContent, title);
    }, 2000),
    [projectId, title]
  );

  const debouncedSaveTitle = useCallback(
    debounce((newTitle) => {
      saveProjectData(editorState, newTitle);
    }, 700),
    [editorState, projectId]
  );

  const handleTitleChange = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    debouncedSaveTitle(newTitle);
  };

  const AI_API_URL = process.env.REACT_APP_AI_API_URL || 'https://pagerift-writer.ue.r.appspot.com/api';

  const handleRewrite = async () => {
    console.log("handleRewrite called");
    console.log("World ID:", worldId);
    const startTime = performance.now();
    
    if (!editorRef.current) {
      console.error("Editor reference is not available");
      return;
    }

    const selectedText = editorRef.current.state.doc.textBetween(
      editorRef.current.state.selection.from,
      editorRef.current.state.selection.to
    );

    console.log("Selected text:", selectedText);

    if (!selectedText) {
      alert('Please select some text to rewrite.');
      return;
    }
  
    setOriginalText(selectedText);
  
    try {
      const timestamp = Date.now();
      const response = await fetch(`${AI_API_URL}/rewrite_stream`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text: selectedText, num_versions: 4, timestamp, world_id: worldId }),
        credentials: 'include',
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} ${errorText}`);
      }
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
  
      let buffer = '';
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
  
        buffer += decoder.decode(value, { stream: true });
        const versions = buffer.split('---');
        
        // Process complete versions
        for (let i = 0; i < versions.length - 1; i++) {
          const version = versions[i].trim();
          if (version) {
            setRewriteOptions(prev => [...prev, version]);
          }
        }
        
        // Keep the incomplete version in the buffer
        buffer = versions[versions.length - 1];
      }
  
      // Process any remaining content in the buffer
      if (buffer.trim()) {
        setRewriteOptions(prev => [...prev, buffer.trim()]);
      }
  
      const totalTime = performance.now() - startTime;
      console.log(`Total rewrite time: ${totalTime}ms`);
    } catch (error) {
      console.error('Error details:', error);
      alert(`An error occurred while processing your rewrite request: ${error.message}`);
    }
  };

  const handleInsert = (rewrittenText) => {
    if (editorRef.current) {
      const { from, to } = editorRef.current.state.selection;
      editorRef.current.commands.setTextSelection({ from, to });
      editorRef.current.commands.insertContent(rewrittenText);
    }
  };

  const handleBookmark = (rewrittenText) => {
    // Implement bookmark functionality here
    console.log("Bookmarked:", rewrittenText);
  };

  if (isLoading) {
    return <div>Loading project...</div>;
  }

  return (
    <div className="main-editor-container">
      <LeftSidebar setIsLeftSidebarExtended={setIsLeftSidebarExtended} />
      <div className="editor-right-wrapper">
        <HorizontalHeader 
          characterCount={characterCount} 
          isSaving={isSaving} 
          title={title} 
          onTitleChange={handleTitleChange}
        />
        <div className="editor-content-wrapper">
          <TiptapEditor 
            editorState={editorState} 
            onEditorChange={(newState) => {
              setEditorState(newState);
              debouncedSave(newState);
            }}
            title={title}
            onTitleChange={handleTitleChange}
            onRewrite={handleRewrite}
            setRewriteOptions={setRewriteOptions}
            setOriginalText={setOriginalText}
            setOperationType={setOperationType}
            ref={editorRef}
            setCharacterCount={setCharacterCount}
            worldId={worldId}
          />
          <RightSidebar 
            rewriteOptions={rewriteOptions}
            originalText={originalText}
            operationType={operationType}
            onInsert={handleInsert}
            onBookmark={handleBookmark}
            isLeftSidebarExtended={isLeftSidebarExtended}
          />
        </div>
      </div>
    </div>
  );
};

export default MainEditor;
