import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import styles from './AuthComponent.module.css';
import SignUpScreen from './AuthScreens/SignUpScreen';
import SignInScreen from './AuthScreens/SignInScreen';
import CodeScreen from './AuthScreens/CodeScreen';
import { PageriftLogo } from './SVGLibrary';
import '../components/AuthComponent.module.css';

const AuthComponent = ({ onLogin }) => {
  const [user, setUser] = useState(null);
  const [authScreen, setAuthScreen] = useState('signIn');

  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setUser(session?.user ?? null);
    };

    fetchSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      const currentUser = session?.user ?? null;
      setUser(currentUser);
      if (currentUser) onLogin(currentUser);
    });

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [onLogin]);

  const handleSignUp = async (email, password) => {
    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: window.location.origin,
        },
      });
      if (error) throw error;
      setAuthScreen('code');
    } catch (error) {
      alert(error.message);
    }
  };

  const handleSignIn = async (email, password) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) throw error;
    } catch (error) {
      alert(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: window.location.origin,
        },
      });
      if (error) throw error;
    } catch (error) {
      alert(error.message);
    }
  };

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setUser(null);
    } catch (error) {
      alert(error.message);
    }
  };

  if (user) {
    return (
      <div className={styles.authContainer}>
        <div className={styles.authForm}>
          <p>Welcome, {user.email}!</p>
          <button onClick={handleLogout} className={styles.button}>Log Out</button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.authContainer}>
      {authScreen === 'signUp' && (
        <SignUpScreen 
          onSignUp={handleSignUp} 
          onSwitchToSignIn={() => setAuthScreen('signIn')}
          onGoogleSignIn={handleGoogleSignIn}
        />
      )}
      {authScreen === 'signIn' && ( 
        <SignInScreen 
          onSignIn={handleSignIn} 
          onSwitchToSignUp={() => setAuthScreen('signUp')}
          onGoogleSignIn={handleGoogleSignIn}
        />
      )}
      {authScreen === 'code' && (
        <CodeScreen 
          onVerify={() => {}} // Implement this function if needed
          onResendCode={() => {}} // Implement this function if needed
        />
      )}
    </div>
  );
};

export default AuthComponent;