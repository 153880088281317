import React, { useEffect, useState } from 'react';
import './Modal.css';

const Modal = ({ isOpen, onClose }) => {
  const [selectedOption, setSelectedOption] = useState('Profile');

  useEffect(() => {
    // Disable scrolling on the body when the modal is open
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      onClose();
    }
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className="modal-overlay" onClick={handleBackdropClick}>
      <div className="modal-content">
        <div className="modal-sidebar">
          <ul>
            <li
              className={`modal-option ${selectedOption === 'Profile' ? 'active' : ''}`}
              onClick={() => handleOptionClick('Profile')}
            >
              Profile
            </li>
            <li
              className={`modal-option ${selectedOption === 'Account' ? 'active' : ''}`}
              onClick={() => handleOptionClick('Account')}
            >
              Account
            </li>
            <li
              className={`modal-option ${selectedOption === 'Preferences' ? 'active' : ''}`}
              onClick={() => handleOptionClick('Preferences')}
            >
              Preferences
            </li>
            {/* Add more options as needed */}
          </ul>
        </div>
        <div className="modal-main-content">
          <button className="modal-close-button" onClick={onClose}>Close</button>
          {selectedOption === 'Profile' && (
            <>
              <h2>Profile Settings</h2>
              {/* Profile settings content */}
            </>
          )}
          {selectedOption === 'Account' && (
            <>
              <h2>Account Settings</h2>
              {/* Account settings content */}
            </>
          )}
          {selectedOption === 'Preferences' && (
            <>
              <h2>Preferences</h2>
              {/* Preferences content */}
            </>
          )}
          {/* Add more content sections as needed */}
        </div>
      </div>
    </div>
  );
};

export default Modal;