import React from 'react';
import './CharacterCount.css';

const CharacterCount = ({ count, isSaving }) => {
    return (
        <div className="character-count-wrapper">
            <div className="character-count">
                Words {count} 
            </div>
            <div className="save-state">
                <div className={`save-indicator ${isSaving ? 'saving' : 'saved'}`}></div>
                <span>{isSaving ? "Saving..." : "Saved"}</span>
            </div>
        </div>
    );
};

export default CharacterCount;